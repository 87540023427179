@import 'vars';

.outline__container {
  display: flex;
  overflow: auto;
  width: 100vw;
}

.outline__grid {
  width: 100vw;
  padding-left: 0;

  .row {
    height: 100%;
    overflow: hidden;

    .outline__grid__minimap {
      height: 100%;
      overflow: auto;
    }

    .outline__grid__beats {
      height: 100%;
      overflow: auto;
      padding: 0;
    }
  }
}

@media (min-width: 992px) {
  .outline__grid__minimap.col-md-3 {
    width: 20%;
  }

  .outline__grid__beats.col-md-9 {
    width: 80%;
  }
}

.filter-list__list li {
  span.filter-list__placeholder {
    display: inline-block;
    width: 14px;
    margin-right: 4px;
  }

  span.glyphicon {
    width: 14px;
    margin-right: 4px;
  }
}

.outline__beat-manual-sort {
  margin-left: 12px;
  color: $yellow-3;
  cursor: pointer;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: $yellow-1;
  }
}

.outline__card-wrapper {
  margin: 14px 20px;

  .outline__card-drop {
    text-align: center;
    margin-bottom: 4px;
    border: 2px dashed $gray-8;
    padding-top: 4px;

    svg {
      color: $gray-8;
    }
  }
}

.outline-list__card-view {
  @include well;
  padding: 20px;
  max-height: 100%;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .outline__card__description {
    &.editing {
      width: 100%;

      .glyphicon-pencil {
        display: none;
      }

      .outline__description__editing {
        .slate-editor__editor {
          .slate-editor__word-counter {
            // space for close button
            bottom: 60px;
          }
        }
      }
    }

    &:not(.editing) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 4px 0px 4px 4px;
      border: 2px dashed transparent;
      border-radius: 4px;
      cursor: pointer;

      .glyphicon-pencil {
        display: block;
        color: $blue-7;
        visibility: hidden;
        margin: 5px;
        align-self: flex-start;
      }
    }
  }

  .outline__card__line-title {
    margin-left: 4px;
  }

  .outline__card-top {
    flex-direction: column;
  }

  .outline__card__line-title,
  .outline__card__grip,
  .outline__card-top {
    display: flex;
    width: 100%;
  }

  .outline__card__grip {
    display: flex;
    align-items: center;
    cursor: move;
    margin-left: -15px;

    svg {
      color: $gray-8;
      // vertical-align: text-top;
    }

    h3 {
      margin: 5px 2px;
      // display: inline-block;
    }

    &.editing {
      display: none;
    }

    &.dragging {
      width: 30%;
      overflow: hidden;

      h3 {
        white-space: nowrap;
      }
    }
  }

  .outline__card-bottom {
    margin-top: 8px;
  }

  .chip-cloud {
    margin: 8px 0;
  }

  .outline-divider {
    padding: 10px 40px 2px 40px;
    border-top: 1px solid $gray-8;
    width: 93%;
  }

  &:hover {
    .outline__card__description:not(.editing) {
      border: 2px dashed $blue-7;
      border-radius: 4px;
      background-color: $gray-9;

      .glyphicon-pencil {
        visibility: visible;
      }
    }
  }
}

.outline-list__card-view.darkmode {
  background-color: $darkmode-darker-background;
  color: $darkmode-text;
  border-color: $darkmode-border;

  &:hover {
    .outline__card__description:not(.editing) {
      border: 2px dashed $darkmode-v2-border;
      background-color: $darkmode-v2-background;
    }
  }
}

.outline__card__label-list {
  margin: 8px 0;

  .label {
    margin-right: 5px;
    padding-top: 4px;
  }
}

.outline__minimap {
  @include well;
  display: block;
  height: calc(100% + 5px);
  overflow-y: auto;
  padding-right: 5px;
  margin-top: -5px;
  border-radius: 0px;

  li {
    &.active > a {
      background-color: $new-item-background;
    }

    & > a {
      color: inherit;
      cursor: pointer;
      padding: 3px 10px 3px 10px !important;

      &:hover {
        background-color: $body-background-color;
      }
    }
  }
}

.outline__minimap__beat-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .outline__minimap__dots {
    display: flex;
    margin-left: 10px;
    flex-wrap: wrap;
  }

  .outline__minimap__card-dot {
    height: 7px;
    width: 7px;
    margin: 1px;
  }

  &.dropping {
    border: 2px dashed $gray-8;
    padding: 2px;
  }

  > span:first-child {
    display: flex;
    white-space: nowrap;
    margin-right: 5px;
  }

  .accented-text {
    margin-left: 5px;
  }
}

.outline__minimap.darkmode {
  background-color: $darkmode-darker-background;
  color: $darkmode-text;

  li.active > a {
    background-color: $darkmode-background;

    &:focus {
      background-color: $darkmode-background;
    }
  }

  li > a:hover {
    background-color: $darkmode-background;
    color: $darkmode-text;
  }
}

.outline__scene-title {
  margin-left: 20px;

  &.darkmode {
    color: $darkmode-text;
  }

  .outline__card-drop {
    text-align: center;
    margin-bottom: 4px;
    border: 2px dashed $gray-8;
    padding-top: 4px;

    svg {
      color: $gray-8;
      height: 20px;
    }
  }
}

.darkmode {
  .outline-list__card-view.darkmode {
    background-color: #24252c;
    border-color: $darkmode-v2-border;
  }

  .outline__minimap.darkmode {
    background-color: #24252c;
    color: $darkmode-v2-darker-text;
    border: 0px;
    border-right: 1px solid $darkmode-v2-border;

    li > a:hover,
    li.active > a,
    li.active > a:focus {
      background-color: $darkmode-v2-card-background;
    }
  }

  .outline__scene-title.darkmode,
  .outline-list__card-view {
    background-color: transparent;
    .outline__card__grip svg {
      color: #bbb;
    }

    .outline-divider {
      border-top: 1px solid $darkmode-v2-border;
    }
  }

  .outline__scene-title {
    color: $darkmode-v2-heading-text;
  }

  .outline-list__card-view.darkmode {
    .outline__card__description:not(.editing) {
      &:hover {
        border: 2px dashed $darkmode-v2-border;
        background-color: $darkmode-v2-background;
      }
    }
  }
}
