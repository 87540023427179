@use 'sass:math';
// copied and adapted from https://upmostly.com/tutorials/build-a-react-switch-toggle-component //

$scale-factor: 2;

input.option-switch-checkbox {
  height: 0;
  width: 0;
  margin: 0;
  visibility: hidden;
}

.option-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: math.div(100px, $scale-factor);
  height: math.div(50px, $scale-factor);
  background-color: $gray-5;
  border-radius: math.div(100px, $scale-factor);
  position: relative;
  transition: background-color 0.2s;

  &.checked {
    background-color: $green-5;
  }
}

.option-switch-label .option-switch-button {
  content: '';
  position: absolute;
  top: 1px; // adjust with scale factor
  left: 1px; // adjust with scale factor
  width: math.div(45px, $scale-factor);
  height: math.div(45px, $scale-factor);
  border-radius: math.div(45px, $scale-factor);
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.option-switch-checkbox:checked + .option-switch-label .option-switch-button {
  left: calc(100% - 1px); // adjust with scale factor
  transform: translateX(-100%);
}

.option-switch-label:active .option-switch-button {
  width: math.div(60px, $scale-factor);
}

.option-switch-labeltext {
  margin-left: 8px;
}

.option-switch-wrapper {
  display: flex;
  align-items: center;

  &.disabled {
    color: $gray-5;
  }
}
